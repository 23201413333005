import _ from 'lodash';

export default {
  props: {
    openedChildComponent: {
      type: String,
    },
  },
  data() {
    return {
      initialValue: {},
    };
  },
  computed: {
    openedSiblingName() {
      return this.$attrs['opened-component'];
    },
    editMode: {
      get() {
        return this.$options.name === this.openedSiblingName;
      },
      set(editMode) {
        return editMode;
      },
    },
  },
  methods: {
    isActivated() { // TO: start toggle flow
      if (this.isSiblingChanged()) {
        this.confirmLeavePopUp();
      } else {
        this.$emit('onEditActivated', {
          openedChildComponent: this.$options.name,
          awaitingOk: '',
        });
      }
    },
    initialData(initialValue) { // TO: Best to initialize on component mount
      this.initialValue = this.copyObj(initialValue);
    },
    confirmLeavePopUp() {
      this.$emit('onEditActivated', {
        openedChildComponent: this.openedSiblingName,
        awaitingOk: this.$options.name,
      });
      this.$parent.$refs['confirm-leave-modal'].show();
    },
    isSiblingChanged() { // TO: add method "getOwnData" to all sibling
      const sibling = this.$parent.$refs[this.openedSiblingName];
      if (!sibling) return false;
      return !_.isEqual(sibling._data.initialValue, sibling.getOwnData());
    },
    cancelSelf() {
      this.$emit('onEditActivated', {
        openedChildComponent: '',
        awaitingOk: '',
      });
    },
    copyObj(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    handleOk() {
      if (this.state.openedChildComponent === 'CpConfigurationLandingPage') {
        this.$refs[this.state.openedChildComponent].clearTouched();
      }
      this.state.openedChildComponent = this.state.awaitingOk;
      this.state.awaitingOk = '';
    },
  },
};
