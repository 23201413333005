<template>
  <div class="card mb-5">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            {{ $t('configurationGeneral.emailConfiguration.title') }}
          </h4>
        </div>
        <cp-edit-buttons
          :edit-mode="editMode"
          @changeMode="changeModeProxy"
          @update="saveEmailState"
        />
      </div>
    </div>
    <div
      v-if="errors.length"
      class="card-body"
    >
      <cp-error-notices :errors="errors" />
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <cp-switcher-button
            edit-mode
            :title="$t('configurationGeneral.emailConfiguration.button.defaultEmailAddress')"
            :open-status="!emailDefaultMode"
            @click="changeEmailMode('default')"
          />
        </div>
        <div class="col-6">
          <cp-switcher-button
            edit-mode
            :title="$t('configurationGeneral.emailConfiguration.button.customEmailAddress')"
            :open-status="emailDefaultMode"
            @click="changeEmailMode('custom')"
          />
        </div>
      </div>
      <div
        v-if="emailDefaultMode"
        class="card border-light mt-3"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <i18n
                path="configurationGeneral.emailConfiguration.message.receiveCommunication"
                tag="div"
              >
                <a
                  :href="`mailto:${customEmailModel.investorCommunicationsEmail}`"
                  target="_blank"
                >
                  <strong> {{ customEmailModel.investorCommunicationsEmail }}.</strong>
                </a>
              </i18n>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="card border-light mt-3"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <i18n
                path="configurationGeneral.emailConfiguration.message.configureYourEmailProvider.text"
                tag="div"
              >
                <a
                  href="#"
                  target="_blank"
                >
                  {{ $t('configurationGeneral.emailConfiguration.message.configureYourEmailProvider.link') }}
                </a>.
              </i18n>
            </div>
          </div>
        </div>
        <hr class="my-0 mx-0">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <label class="form-label">
                {{ $t('configurationGeneral.emailConfiguration.input.customEmailAddress') }}
              </label>
              <div>
                <cp-editable-input
                  v-model="customEmailModel.investorCommunicationsEmail"
                  :edit-mode="editMode"
                  :text="customEmailModel.investorCommunicationsEmail"
                  strong
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import CpSwitcherButton from '~/components/common/switcher-button';
import CpEditButtons from '~/components/common/edit-buttons';
import CpErrorNotices from '~/components/common/error-notices';
import CpComponentToggleMixin from '~/mixins/component-toggle';
import CpEditableInput from '~/components/common/editable-input';

export default {
  name: 'CpConfigurationEmail',
  components: {
    CpEditButtons,
    CpSwitcherButton,
    CpEditableInput,
    CpErrorNotices,
  },
  mixins: [
    CpComponentToggleMixin,
  ],
  data() {
    return {
      errors: [],
      customEmailModel: {
        investorCommunicationsEmail: '',
        type: 'default',
      },
    };
  },
  computed: {
    ...mapGetters('configuration', ['getGeneralEmail']),
    emailDefaultMode() {
      return this.customEmailModel && this.customEmailModel.type === 'default';
    },
  },
  mounted() {
    const generalEmail = this.getGeneralEmail;
    if (generalEmail) {
      this.customEmailModel = JSON.parse(JSON.stringify(generalEmail));
    }
  },
  methods: {
    ...mapActions('configuration', ['updateEmailConfiguration']),
    changeEmailMode(mode) {
      if (this.$isLimitedAccess() && mode === 'custom') {
        return this.$showLimitAccessError();
      }
      if (!this.editMode) {
        this.changeModeProxy();
      }
      this.customEmailModel.type = mode;
    },
    saveEmailState() {
      const { idIssuer: issuerId } = this.$route.params;
      this.errors = [];
      this.updateEmailConfiguration({
        issuerId,
        body: this.customEmailModel,
      })
        .then(() => {
          this.cancelSelf();
        })
        .catch((err) => {
          if (err.response.body && err.response.body.message) {
            this.errors = err.response.body.message.split(',');
          }
        });
    },
    changeModeProxy() {
      if (this.$isLimitedAccess()) {
        return this.$showLimitAccessError();
      }
      this.customEmailModel = JSON.parse(JSON.stringify(this.getGeneralEmail || {}));
      this.initialData(this.customEmailModel);
      if (!this.editMode) {
        this.isActivated();
      } else {
        this.cancelSelf();
      }
    },
    cancelIfActive() {
      if (this.editMode) {
        this.customEmailModel = JSON.parse(JSON.stringify(this.getGeneralEmail || {}));
        this.cancelSelf();
      }
    },
    getOwnData() {
      return this.customEmailModel;
    },
  },
};
</script>
