<template>
  <b-btn-group
    size="sm"
    variant="primary-outline"
  >
    <b-btn
      v-for="(title, index) in group"
      :key="index"
      :variant="getVariant(index)"
      :disabled="!editMode"
      @click="$emit('click', index)"
    >
      <span
        v-if="getActiveBtn(index)"
        class="ion ion-md-checkmark"
      />
      &nbsp;&nbsp;{{ title }}
    </b-btn>
  </b-btn-group>
</template>

<script>
export default {
  name: 'CpBtnGroup',
  props: {
    group: {
      type: Object,
      required: true,
    },
    activeBtn: {
      type: String,
      default: '',
    },
    editMode: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    getVariant(btn) {
      return this.getActiveBtn(btn) ? 'primary' : 'outline-primary';
    },
    getActiveBtn(btn) {
      return btn === this.activeBtn;
    },
  },
};
</script>
