<template>
  <div class="card mb-5">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            {{ $t('configurationGeneral.generalProperties.title') }}
          </h4>
        </div>
        <cp-edit-buttons
          :edit-mode="editMode"
          @changeMode="changeModeProxy"
          @update="save"
        />
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <h5>{{ $t('configurationGeneral.generalProperties.input.issuerName.text') }}</h5>
          <b-input
            v-if="editMode"
            v-model="issuerName"
            maxlength="50"
            :placeholder="$t('configurationGeneral.generalProperties.input.issuerName.placeholder')"
          />
          <h3
            v-else
            class="m-0"
          >
            {{ getGeneral.issuerName }}
          </h3>
          <div class="leiCode">
            <h5>{{ $t('configurationGeneral.generalProperties.input.issuerLeiCode.text') }}</h5>
            <b-input
              v-if="editMode"
              v-model="issuerLeiCode"
              maxlength="20"
              minlength="20"
              :placeholder="$t('configurationGeneral.generalProperties.input.issuerLeiCode.placeholder')"
              @input="isValidLei"
            />
            <h6
              v-else
              class="m-0"
            >
              {{ getGeneral.issuerLeiCode | nullToMinus }}
            </h6>
            <p
              v-if="isSpecialChars"
              class="error-msg"
            >
              No special characters allowed
            </p>
          </div>
        </div>
        <div class="col-6">
          <h5>{{ $t('configurationGeneral.generalProperties.input.beneficialOwnerInformation') }}</h5>
          <table class="table table-striped table-borderless mb-0 mt-3">
            <tbody>
              <tr>
                <td>{{ $t('configurationGeneral.generalProperties.label.name') }}</td>
                <td>
                  <b-input
                    v-if="editMode"
                    v-model="beneficialOwnerInformationName"
                    maxlength="35"
                  />
                  <h6
                    v-else
                    class="m-0"
                  >
                    {{ getGeneral.beneficialOwnerInformation.name }}
                  </h6>
                </td>
              </tr>
              <tr>
                <td>{{ $t('configurationGeneral.generalProperties.label.surname') }}</td>
                <td>
                  <b-input
                    v-if="editMode"
                    v-model="beneficialOwnerInformationSurname"
                    maxlength="35"
                  />
                  <h6
                    v-else
                    class="m-0"
                  >
                    {{ getGeneral.beneficialOwnerInformation.surname }}
                  </h6>
                </td>
              </tr>
              <tr>
                <td>{{ $t('configurationGeneral.generalProperties.label.dateOfBirth') }}</td>
                <td>
                  <cp-date-picker
                    v-if="editMode"
                    v-model="beneficialOwnerInformationDateOfBirth"
                    initial-view="year"
                    :open-date="openDate"
                    :disabled-dates="disabledDates"
                  />
                  <h6
                    v-else
                    class="m-0"
                  >
                    {{ getGeneral.beneficialOwnerInformation.dateOfBirth | dateFilter }}
                  </h6>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <hr class="my-0 mx-0">
    <div class="card-body">
      <h5>{{ $t('configurationGeneral.generalProperties.input.colorScheme') }}</h5>
      <div
        v-if="isLoading"
        class="row"
      >
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label">
              {{ $t('configurationGeneral.generalProperties.label.primaryColor') }}
            </label>
            <input
              ref="primaryColor"
              data-model-name="primaryColor"
              :value="primaryColor"
              class="form-control"
              :disabled="!editMode"
            >
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label">
              {{ $t('configurationGeneral.generalProperties.label.secondaryColor') }}
            </label>
            <input
              ref="secondaryColor"
              data-model-name="secondaryColor"
              :value="getGeneral.colorScheme.secondaryColor"
              class="form-control"
              :disabled="!editMode"
            >
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label">
              {{ $t('configurationGeneral.generalProperties.label.actionColor') }}
            </label>
            <input
              ref="actionColor"
              data-model-name="actionColor"
              :value="getGeneral.colorScheme.actionColor"
              class="form-control"
              :disabled="!editMode"
            >
          </div>
        </div>
      </div>
    </div>

    <hr class="my-0 mx-0">
    <!--editable mode-->
    <div
      v-if="editMode"
      class="card-body"
    >
      <div class="row">
        <div class="col-md-4">
          <h5>{{ $t('configurationGeneral.generalProperties.input.logoSquare') }}</h5>
          <cp-file-uploader
            id="logo-uploader"
            :style="primaryColorBg"
            :default-url="logoSquareImage"
            @onChange="onLogoSquareUploaded"
          />
          <table class="table table-striped table-borderless mb-0 mt-3">
            <tbody>
              <tr>
                <td>{{ $t('configurationGeneral.generalProperties.label.recommendedRatio') }}</td>
                <td><strong>{{ $t('configurationGeneral.generalProperties.label.recommendedRatioValue') }}</strong></td>
              </tr>
              <tr>
                <td>{{ $t('configurationGeneral.generalProperties.label.recommendedFormat') }}</td>
                <td><strong>{{ $t('configurationGeneral.generalProperties.label.recommendedFormatValue') }}</strong></td>
              </tr>
              <tr>
                <td>{{ $t('configurationGeneral.generalProperties.label.minimumHeight') }}</td>
                <td><strong>{{ $t('configurationGeneral.generalProperties.label.minimumHeightValue') }}</strong></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-4">
          <h5>{{ $t('configurationGeneral.generalProperties.input.logoFullName') }}</h5>
          <cp-file-uploader
            id="logo-dark-uploader"
            :style="{ backgroundColor: '#000' }"
            :default-url="logoFullNameImage"
            @onChange="onLogoFullNameUploaded"
          />
          <table class="table table-striped table-borderless mb-0 mt-3">
            <tbody>
              <tr>
                <td>{{ $t('configurationGeneral.generalProperties.label.recommendedRatio') }}</td>
                <td><strong>{{ $t('configurationGeneral.generalProperties.label.recommendedRatioValue') }}</strong></td>
              </tr>
              <tr>
                <td>{{ $t('configurationGeneral.generalProperties.label.recommendedFormat') }}</td>
                <td><strong>{{ $t('configurationGeneral.generalProperties.label.recommendedFormatValue') }}</strong></td>
              </tr>
              <tr>
                <td>{{ $t('configurationGeneral.generalProperties.label.minimumHeight') }}</td>
                <td><strong>{{ $t('configurationGeneral.generalProperties.label.minimumHeightValue') }}</strong></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-4">
          <h5>{{ $t('configurationGeneral.generalProperties.input.favicon') }}</h5>
          <cp-file-uploader
            id="logo-uploader"
            :default-url="faviconImage"
            @onChange="onFaviconUploaded"
          />
          <table class="table table-striped table-borderless mb-0 mt-3">
            <tbody>
              <tr>
                <td>{{ $t('configurationGeneral.generalProperties.label.recommendedFormat') }}</td>
                <td><strong>{{ $t('configurationGeneral.generalProperties.label.recommendedFormatValue') }}</strong></td>
              </tr>
              <tr>
                <td>{{ $t('configurationGeneral.generalProperties.label.recommendedSize') }}</td>
                <td><strong>{{ $t('configurationGeneral.generalProperties.label.recommendedSizeValue') }}</strong></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--editable false-->
    <div
      v-else
      class="card-body"
    >
      <div class="row">
        <div class="col-md-4 d-flex">
          <div
            class="card border-light mt-3 d-flex justify-content-center align-items-center py-5 w-100 cp-file-preview"
            :style="{ backgroundColor: getGeneral.colorScheme.primaryColor, }"
          >
            <h5
              class="position-absolute text-white"
              style="top: 15px; left: 15px;"
            >
              {{ $t('configurationGeneral.generalProperties.input.logoSquare') }}
            </h5>
            <img
              v-b-modal.general-logo
              :class="`d-inline-block ${ getGeneral.images.logoSquare ? 'link': '' }`"
              :src="logoSquareImage"
              alt="Logo"
            >
          </div>
          <b-modal
            v-if="getGeneral.images.logoSquare"
            id="general-logo"
            size="lg"
            class="cp-preview-modal"
            :title="$t('configurationGeneral.previewModal.title.logoSquare')"
            hide-footer
          >
            <img
              class="d-inline-block"
              :src="logoSquareImage"
              alt="Logo"
            >
          </b-modal>
        </div>
        <div class="col-md-4 d-flex">
          <div
            class="card border-light mt-3 d-flex justify-content-center align-items-center py-5 w-100 cp-file-preview"
          >
            <h5
              class="position-absolute"
              style="top: 15px; left: 15px;"
            >
              {{ $t('configurationGeneral.generalProperties.input.logoFullName') }}
            </h5>
            <img
              v-b-modal.general-logo-dark
              :class="`d-inline-block ${ getGeneral.images.logoFullName ? 'link': '' }`"
              class="d-inline-block"
              style="border: solid 2px black"
              :src="logoFullNameImage"
              alt="Logo Dark"
            >
          </div>
          <b-modal
            v-if="getGeneral.images.logoFullName"
            id="general-logo-dark"
            size="lg"
            class="cp-preview-modal"
            :title="$t('configurationGeneral.previewModal.title.logoFullName')"
            hide-footer
          >
            <img
              class="d-inline-block"
              :src="logoFullNameImage"
              alt="Logo Dark"
              :style="{ backgroundColor: '#000' }"
            >
          </b-modal>
        </div>
        <div class="col-md-4 d-flex">
          <div class="card border-light mt-3 d-flex justify-content-center align-items-center py-5 w-100 cp-file-preview">
            <h5
              class="position-absolute"
              style="top: 15px; left: 15px;"
            >
              {{ $t('configurationGeneral.generalProperties.input.favicon') }}
            </h5>
            <img
              v-b-modal.general-favicon
              :class="`d-inline-block ${ getGeneral.images.favicon ? 'link': '' }`"
              :src="faviconImage"
              alt="Favicon"
            >
          </div>
          <b-modal
            v-if="getGeneral.images.favicon"
            id="general-favicon"
            size="mg"
            class="cp-preview-modal"
            :title="$t('configurationGeneral.previewModal.title.favicon')"
            hide-footer
          >
            <img
              class="d-inline-block"
              :src="faviconImage"
              alt="Favicon"
            >
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { mapState, mapGetters, mapActions } from 'vuex';

import CpEditButtons from '~/components/common/edit-buttons';
import CpFileUploader from '~/components/common/standalone-components/cp-file-uploader';
import CpDatePicker from '~/components/common/standalone-components/inputs/cp-date-picker';
import staticPathGenerationMixin from '~/mixins/static-path-generation';
import CpComponentToggleMixin from '~/mixins/component-toggle';

const getFileAPIBase = () => process.env.SWAGGER_PATH.replace('swagger.json', '');
export default {
  name: 'CpConfigurationGeneralProperties',
  components: {
    CpFileUploader,
    CpEditButtons,
    CpDatePicker,
  },
  mixins: [
    staticPathGenerationMixin,
    CpComponentToggleMixin,
  ],
  data() {
    return {
      customPrimaryColor: '',
      colorFields: ['primary', 'secondary', 'action'],
      issuerName: '',
      issuerLeiCode: '',
      beneficialOwnerInformationName: '',
      beneficialOwnerInformationSurname: '',
      beneficialOwnerInformationDateOfBirth: '',
      openDate: new Date('1990-01-01'),
      isSpecialChars: false,
    };
  },
  computed: {
    ...mapState('configuration', ['isLoading']),
    ...mapGetters('configuration', ['getGeneral']),
    primaryColorBg() {
      return { backgroundColor: this.customPrimaryColor || this.getGeneral.colorScheme.primaryColor };
    },
    primaryColor() {
      return this.customPrimaryColor || this.getGeneral.colorScheme.primaryColor;
    },
    logoSquareImage() {
      const url = this.getGeneral.images.logoSquare || this.getImagePath('upload.png', 'theme');
      return `${url}?random=${new Date().getTime()}`;
    },
    logoFullNameImage() {
      const url = this.getGeneral.images.logoFullName || this.getImagePath('upload.png', 'theme');
      return `${url}?random=${new Date().getTime()}`;
    },
    faviconImage() {
      const url = this.getGeneral.images.favicon || this.getImagePath('upload.png', 'theme');
      return `${url}?random=${new Date().getTime()}`;
    },
    disabledDates() {
      const today = new Date();
      return {
        from: new Date(today.getFullYear() - 18, today.getMonth()),
      };
    },
  },
  mounted() {
    window.jQuery = $;
    if (this.isLoading) {
        import('~/vendor/libs/minicolors/minicolors').then(() => {
          const { primaryColor, secondaryColor, actionColor } = this.$refs;
          const pickersClasses = [primaryColor, secondaryColor, actionColor];
          primaryColor.onchange = this.updatePrimaryColor;
          pickersClasses.forEach((picker) => {
            $(picker).minicolors({
              control: 'hue',
              position: 'bottom left',
            });
          });
        });
    }
    this.initialData(this.getGeneral);
    this.issuerName = this.getGeneral.issuerName;
    this.issuerLeiCode = this.getGeneral.issuerLeiCode;
    this.beneficialOwnerInformationName = this.getGeneral.beneficialOwnerInformation.name;
    this.beneficialOwnerInformationSurname = this.getGeneral.beneficialOwnerInformation.surname;
    this.beneficialOwnerInformationDateOfBirth = this.getGeneral.beneficialOwnerInformation.dateOfBirth;
  },
  created() {
    this.model = { ...this.getGeneral };
  },
  methods: {
    ...mapActions('configuration', ['updateGeneralConfiguration']),
    updatePrimaryColor(e) {
      if (e && e.target && e.target.value) {
        this.customPrimaryColor = e.target.value;
      }
    },
    onLogoSquareUploaded({ fileKey }) {
      this.getGeneral.images.imageLogoSquareKey = fileKey;
    },
    onLogoFullNameUploaded({ fileKey }) {
      this.getGeneral.images.imageLogoFullNameKey = fileKey;
    },
    onFaviconUploaded({ fileKey }) {
      this.getGeneral.images.imageFaviconKey = fileKey;
    },
    getFileUploadUrl() {
      const { idIssuer: issuerId } = this.$route.params;
      return `${getFileAPIBase()}issuers/${issuerId}/configuration/general/upload-tmp-asset-to-server`;
    },
    isValidLei(value) {
      const specialCharacters = /[`!@#$%^&*()_+\-=\\|,.<>?~]/;
      this.isSpecialChars = specialCharacters.test(value);
    },
    clearTouched() {
      delete this.getGeneral.images.imageLogoSquareKey;
      delete this.getGeneral.images.imageLogoFullNameKey;
      delete this.getGeneral.images.imageFaviconKey;
      this.customPrimaryColor = '';

      // jquery doesnt connect well to VUE, have to work around it.
      const { primaryColor, secondaryColor, actionColor } = this.getGeneral.colorScheme;
      const {
        primaryColor: primaryPicker,
        secondaryColor: secondaryPicker,
        actionColor: actionPicker,
      } = this.$refs;
      $(primaryPicker).minicolors('value', primaryColor);
      $(secondaryPicker).minicolors('value', secondaryColor);
      $(actionPicker).minicolors('value', actionColor);
    },
    changeModeProxy() {
      if (this.editMode) {
        this.issuerName = this.getGeneral.issuerName;
        this.issuerLeiCode = this.getGeneral.issuerLeiCode;
        this.beneficialOwnerInformationName = this.getGeneral.beneficialOwnerInformation.name;
        this.beneficialOwnerInformationSurname = this.getGeneral.beneficialOwnerInformation.surname;
        this.beneficialOwnerInformationDateOfBirth = this.getGeneral.beneficialOwnerInformation.dateOfBirth;
        // on cancel clear the image IDs to prevent accidental upload
        this.clearTouched();
        this.cancelSelf();
      } else {
        this.isActivated();
      }
    },
    cancelIfActive() {
      if (this.editMode) {
        this.clearTouched();
        this.cancelSelf();
      }
    },
    getOwnData() {
      const copy = this.copyObj(this.getGeneral);
      copy.colorScheme = {
        primaryColor: this.$refs.primaryColor.value,
        secondaryColor: this.$refs.secondaryColor.value,
        actionColor: this.$refs.actionColor.value,
      };
      if (this.issuerName) copy.issuerName = this.issuerName;
      if (this.issuerLeiCode) copy.issuerLeiCode = this.issuerLeiCode;
      if (this.beneficialOwnerInformationName) copy.beneficialOwnerInformationName = this.beneficialOwnerInformationName;
      if (this.beneficialOwnerInformationSurname) copy.beneficialOwnerInformationSurname = this.beneficialOwnerInformationSurname;
      if (this.beneficialOwnerInformationDateOfBirth) copy.beneficialOwnerInformationDateOfBirth = this.beneficialOwnerInformationDateOfBirth;
      return copy;
    },
    save() {
      const { idIssuer: issuerId } = this.$route.params;
      const { imageLogoSquareKey, imageLogoFullNameKey, imageFaviconKey } = this.getGeneral.images;
      const colorScheme = {
        primaryColor: this.$refs.primaryColor.value,
        secondaryColor: this.$refs.secondaryColor.value,
        actionColor: this.$refs.actionColor.value,
      };
      const images = {
        imageLogoSquareKey: imageLogoSquareKey || undefined,
        imageLogoFullNameKey: imageLogoFullNameKey || undefined,
        imageFaviconKey: imageFaviconKey || undefined,
      };
      const beneficialOwnerInformation = {
        name: this.beneficialOwnerInformationName === undefined ? this.getGeneral.beneficialOwnerInformation.name : this.beneficialOwnerInformationName,
        surname: this.beneficialOwnerInformationSurname === undefined ? this.getGeneral.beneficialOwnerInformation.surname : this.beneficialOwnerInformationSurname,
        dateOfBirth: this.beneficialOwnerInformationDateOfBirth === undefined ? this.getGeneral.beneficialOwnerInformation.dateOfBirth : this.beneficialOwnerInformationDateOfBirth,
      };

      const body = {
        issuerName: this.issuerName || this.getGeneral.issuerName,
        issuerLeiCode: this.issuerLeiCode === undefined ? this.getGeneral.issuerLeiCode : this.issuerLeiCode,
        beneficialOwnerInformation,
        colorScheme,
        images,
      };
      if (body.issuerLeiCode === '') body.issuerLeiCode = null;
      this.updateGeneralConfiguration({ issuerId, body }).then(this.cancelSelf);
    },
  },
};
</script>

<style src="~/vendor/libs/minicolors/minicolors.css"></style>
<style lang="scss" scoped>
  .cp-preview-modal{
    text-align: center;
    img{
      max-width:90%;
    }
  }
  .leiCode{
    margin-top: 10%;
  }
  .d-inline-block{
   max-width: 100%;
    max-height: 100%;
  }
  .cp-file-preview{
    min-height:256px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      max-height: 180px;
      max-width: 70%;
      margin: auto;
    }
    img.link{
      cursor: pointer;
    }
    .error-msg{
      font-size: 85%;
      color: #f7321e;
      margin: 0.25rem;
      display: block !important;
    }
  }
</style>
