<template>
  <div @click="$emit('click')">
    <b-button
      v-if="openStatus"
      variant="outline-primary"
      class="btn-block m-0"
      :disabled="!editMode"
    >
      {{ title }}
    </b-button>
    <b-button
      v-else
      variant="primary"
      class="btn-block m-0"
      :disabled="!editMode"
    >
      <i class="ion ion-md-checkmark " />
      {{ title }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'CpSwitcherButton',
  props: {
    openStatus: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
